<template>
	<section>
		<b-form-group>
			<template v-for="(item, index) in options">
				<b-form-radio v-model="inputValue" :key="index" :value="item.value" :disabled="disabled">
					<p>{{ item.text }}</p>
				</b-form-radio>
			</template>
		</b-form-group>
		<b-button @click.prevent="uncheck" :disabled="disabled" v-if="this.inputValue">Nulstil</b-button>
	</section>
</template>

<script>
export default {
	name: 'InputRadio',
	props: ['options', 'value', 'disabled', 'required'],
	methods: {
		uncheck() {
			this.inputValue = null
		},
	},
	computed: {
		inputValue: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
	},
}
</script>

<style></style>
