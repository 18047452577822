<template>
  <section class="row">
    <div class="col-12" v-if="showTax">
      <b-form-group>
        <template v-for="(item, index) in tax">
          <b-form-radio
            v-model="inputValue"
            :key="index"
            :value="'SK' + item.code"
            :disabled="disabled"
          >
            <i
              >{{$t('global.taxCode')}} {{ item.code}} <template v-if="item.taxFree"> {{$t('global.taxFree')}} </template></i
            >
          </b-form-radio>
        </template>
      </b-form-group>
    </div>
  </section>
</template>

<script>
export default {
  name: "InsuranceHeader",
  props: {
    disabled: Boolean,
    tax: {
      type: Array,
    },
    taxFree: {
      type: Boolean,
    },
    showTax: {
      type: Boolean,
    },
    value: String,
  },
  data() {
    return {};
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss">
</style>