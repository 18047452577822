<template>
  <section class="mb-2">
    <div class="">
      <b-form-input
        v-model.lazy="inputValue"
        type="text"
        :disabled="disabled"
        :placeholder="limit"
        :required="required"
        :state="isRequiredAndFilled"
        @blur="isInputActive = false"
        @focus="isInputActive = true"
      />
    </div>
    <div class="col-auto"></div>
  </section>
</template>

<script>
import requiredFieldMixin from "../../../mixins/requiredFieldMixin";

export default {
  name: "InputMinMax",
  props: ["value", "disabled", "limit", "required"],
  mixins: [requiredFieldMixin],
  data() {
    return { isInputActive: false };
  },
  computed: {
    inputValue: {
      get() {
        if (this.value) {
          if (this.isInputActive) {
            return this.value + "".toString().replace(/,/, ".");
          } else {
            var newVal = parseFloat(this.value).toLocaleString("da-DK");
            return newVal;
          }
        } else return this.value;
      },
      set(val) {
        var newVal = val.toString().replace(/,/, ".");
        this.$emit("input", Number(newVal));
      },
    },
  },
};
</script>

<style>
</style>