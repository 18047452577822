<template>
  <section class="row">
    <div class="col-12">
      <b-form-checkbox
        type="checkbox"
        v-model="inputValue"
        :value="'SK' + code"
        :disabled="disabled"
      >
      {{ text }}
      </b-form-checkbox>
    </div>
  </section>
</template>

<script>
export default {
  name: "InputTaxCodeCheck",
  props: ["text", "value", "disabled", "code"],
  data() {
    return {};
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss">
</style>